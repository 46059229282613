import { graphql } from 'gatsby';
import { HeadInner } from '../../../components/Head';
import { CompleteLayout } from '../../../components/forms/CompleteLayout';
import { Layout } from '../../../components/layout';

export const Head: React.FC<{
  data: GatsbyTypes.DocumentsSlugQueryQuery;
}> = (props) => {
  const slug = props.data.contentfulDocument?.slug;
  return (
    <HeadInner
      path={`/documents/${slug}/complete`}
      title='資料請求ありがとうございます'
      noindex
    />
  );
};

const CompletePage: React.FC<{
  data: GatsbyTypes.DocumentsSlugQueryQuery;
}> = (props) => {
  const title = props.data.contentfulDocument?.title;
  const slug = props.data.contentfulDocument?.slug;
  const links = [
    { name: 'お役立ち資料', path: '/documents/' },
    { name: title || 'お役立ち資料ダウンロード', path: `/documents/${slug}` },
    { name: '資料請求を受け付けました', path: `/documents/${slug}/complete` },
  ];

  return (
    <Layout
      header='simple'
      footer='simple'
      background='simple'
      isBottomMenu={false}
      links={links}
    >
      <CompleteLayout>
        <h2>資料請求を受け付けました</h2>
      </CompleteLayout>
    </Layout>
  );
};

export const query = graphql`
  query DocumentsSlugQuery($id: String) {
    contentfulDocument(id: { eq: $id }) {
      title
      slug
    }
  }
`;

export default CompletePage;
